import { colors } from "components/Theme/WebTheme.js";
import { container } from "./material-kit-react.js"
import {FONT_SECONDARY} from "../common/sharedFunctions";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    borderRadius: "8px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: "#1E2330",
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: colors.WHITE
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  normalButton: {
    margin:'8px',
    fontFamily: FONT_SECONDARY,
    padding: '6px', 
    minHeight: '50px',
    fontSize: '16px',
    backgroundColor: colors.DARKONE,
    color: '#fff',
    paddingLeft: "1.5rem",  
    paddingRight: "1.5rem",
    borderRadius: '12px',
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: colors.BLACK,
      color: '#fff'
    },
  },
};
export default footerStyle;
