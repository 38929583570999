import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import { FONT_FAMILY } from 'common/sharedFunctions';

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const { open, onClose, children } = props;
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{fontFamily:FONT_FAMILY,fontWeight:600,borderBottom: `1px solid ${colors.TABLE_BORDER}`,}}>{"Shzzzzz!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{fontFamily:FONT_FAMILY,padding:'50px',paddingTop:'20px',paddingBottom:'20px'}}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{
            backgroundColor: colors.GRAY_50,
            fontSize: 12,
            color: colors.GRAY_500,
            padding: 7,
            borderRadius: "8px",
            fontWeight: 500,
            border: `1px solid ${colors.Table_Header}`,
            fontFamily: FONT_FAMILY,
          }}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
