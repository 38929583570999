export const FirebaseConfig = {
	"projectId": "shazam-africa",
	"appId": "1:1095254243390:web:93a87f06dd5c1fe7d7796f",
	"databaseURL": "https://shazam-africa-default-rtdb.firebaseio.com",
	"storageBucket": "shazam-africa.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBNIM0k4cwyWjJ0YtTHLK0KtWrtpObL2rU",
	"authDomain": "shazam-africa.firebaseapp.com",
	"messagingSenderId": "1095254243390",
	"measurementId": "G-J96E769L5H"
};