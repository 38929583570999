import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { colors } from '../components/Theme/WebTheme';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!checkCookieConsent()) {
        setShowBanner(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const checkCookieConsent = () => {
    return document.cookie.split(';').some((item) => item.trim().startsWith('cookieConsent='));
  };

  const handleAccept = () => {
    document.cookie = 'cookieConsent=true; max-age=31536000; path=/';
    setShowBanner(false);
  };

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <Slide direction="up" in={showBanner} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.WHITE,
          padding:'20px',
          paddingLeft: '40px',
          paddingRight: '40px',
          boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 9999,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          minHeight: "120px",
          alignItems: 'center',
          justifyContent: 'space-between',
        }}> 
      <div sx={{paddingLeft:'50px'}}>
        <Typography sx={{fontSize:'28px', fontWeight:600,}}>{t('cookie_consent_title')}</Typography>
            <Typography variant="body1" sx={{fontSize:'18px', marginBottom: { xs: '18px', sm: 0 }, marginRight: { sm: '16px' } }}>
            {t('cookie_consent_message')}
            </Typography>
        </div>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '8px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            sx={{
              backgroundColor: colors.Black,
              borderRadius: "12px",
              padding: 2,
              color: colors.WHITE,
              '&:hover': {
                backgroundColor:colors.GRAY_600,
                opacity: 0.9,
              },
            }}
          >
            {t('accept_cookies')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => window.open('/privacy-policy', '_blank')}
            sx={{
              borderColor: colors.BLACK,
              borderRadius: "12px",
              padding: 2,
              color: colors.BLACK,
              '&:hover': {
                borderColor: colors.BLACK,
                color: colors.WHITE,
                backgroundColor: '#000',
              },
            }}
          >
            {t('cookie_policy')}
          </Button>
        </Box>
        <Button
          onClick={handleClose}
          sx={{
            minWidth: 'auto',
            padding: '8px',
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: colors.BLACK,
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Slide>
  );
};

export default CookieConsentBanner;