export const colors = {
    LandingPage_Background: '#FFFFFF',
    LandingPage_Description: '#373737',
    Button_Primary: '#1E2330',
    Button_Secondary: '#000', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    Black: '#000000',
    Header: '#6b7280',
    Header_Text: "#FFFFFF",
    Header_Text_back: "#1E2330",
    Table_Header: '#BFCACD',
    ACTION_BUTTON_BACK: '#dbeafe',
    ACTION_BUTTON_BORDER: '#60a5fa',
    ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
    ALERT_BUTTON_CLOSE_COLOR:  "#ea969d",
    HEADER: '#1E2330',
    BORDER_BACKGROUND: "#f3f4f6",
    BORDER_BACKGROUND_DARK: '#6b7280',
    CAR_BOX_EVEN:'#C8D8F4',
    CAR_BOX_ODD:'#F3F3F1',
    CARD_LABEL:"#9ca3af",
    CARD_DETAIL:"#F5F5F5",
    STATUS_COMPLETE:"#254F1A",
    STATUS_CANCELED:"#780016",
    STATUS_PENDING:"#D6A336",
    ROW_SELECTED:"#e5e7eb",
    TABLE_BORDER:"#e5e7eb",
    SUB_2:"#019b9d",
    WALLET_CARD:"#CC3372",
    WALLET_CARD_SHADOW:"#9E9E9E",
    LABEL:"#9ca3af",
    FACEBOOK_COLOR:"#3b5998",
    BLUE:"#00acee",
    MUTE_GREEN:"#a5eebe",
    MUTE_YELLOW:"#fdd484",
    MUTE_BLUE:"#b8d9ff",
    MUTE_PURPLE:"#c0b0ff",
    MUTE_ORANGE: "#ff747c",
    
    PRIMARYONE: "#780016",
    SECONDARYONE: "#C8D8F4",
    LIGHTONE: "#F3F3F1",
    DARKONE: "#1E2330",

    TRANSPARENT: "transparent",
    WHITE: "#ffffff",
    BLACK: "#000000",
    RED: "#780016",
    GREEN: "#254F1A",
    YELLOW: "#D6A336",
    VIOLET:"#A755C2",
    FOOTERTOP: "#e2e8f0",

    RED_50:  "#fef2f2",
    RED_100: "#fee2e2",
    RED_200: "#fecaca",
    RED_300: "#fca5a5",
    RED_400: "#f87171",
    RED_500: "#ef4444",
    RED_600: "#dc2626",

    GREEN_50:  "#f0fdf4",
    GREEN_100: "#dcfce7",
    GREEN_200: "#bbf7d0",
    GREEN_300: "#86efac",
    GREEN_400: "#4ade80",
    GREEN_500: "#22c55e",

    YELLOW_50:  "#fefce8",
    YELLOW_100: "#fef9c3",
    YELLOW_200: "#fef08a",
    YELLOW_300: "#fde047",

    GRAY_50:  "#f9fafb",
    GRAY_100: "#f3f4f6",
    GRAY_200: "#e5e7eb",
    GRAY_300: "#d1d5db",
    GRAY_400: "#9ca3af",
    GRAY_500: "#6b7280",
    GRAY_600: "#4b5563",
}