import React from 'react';
import { Typography, Radio, Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import styles from '../styles/loginPage.js';

const useStyles = makeStyles(styles);

const StepOne = ({ selectedUserType, onUserTypeChange, onContinue }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.stepContainer}>
      <Typography variant="h5" className={classes.stepTitle}>
        {t('signup_form_tellus')}
      </Typography>
      <div className={classes.radioGroup}>
        {['customer', 'driver', 'fleetadmin'].map((type) => (
          <div
            key={type}
            className={`${classes.radioOption} ${
              selectedUserType === type ? classes.radioOptionSelected : ''
            }`}
            onClick={() => onUserTypeChange(type)}
          >
            <Radio
              checked={selectedUserType === type}
              onChange={() => onUserTypeChange(type)}
              value={type}
              name="user-type"
              inputProps={{ 'aria-label': type }}
              className={classes.radioInput}
            />
            <Typography className={classes.radioLabel}>
              {t(`${type}_option_text`)}
            </Typography>
          </div>
        ))}
      </div>
      <Button 
        onClick={onContinue} 
        disabled={!selectedUserType}
        className={classes.normalButton}
        style={{marginTop:"10px"}}
      >
        {t('continue')}
      </Button>
    </div>
  );
};

export default StepOne;