import { makeStyles } from '@mui/styles';
import { colors } from 'components/Theme/WebTheme';
import { FONT_SECONDARY } from 'common/sharedFunctions';

const styles = (theme) => {
  return {
    toolBar: {
      height: '10vh',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      backgroundColor: 'white',
    },
    logo: {
      color: 'blue',
      cursor: 'pointer',
    },
    link: {
      color: "#000"
    },
    menuIcon: {
      color: '#000',
    },
    formContainer: {
      flexGrow: 1,
      padding: '10px',
      maxWidth: '700px',
      margin: '30px auto',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
      },
    },
    form: {
      marginTop: '30px',
    },
    formHeading: {
      textAlign: 'center',
    },
    heroBox: {
      width: '100%',
      display: 'flex',
      minHeight: '600px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    heroTitleSection: {
      // Default for lg screens and above (left align, justify left)
      textAlign: 'left',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      // Apply center alignment on md to xs screens
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    heroImg: {
      // Default for lg screens
      marginTop: '16px',
      marginBottom: '16px',
      // Increase margin on md screens
      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: '60px',
        marginBottom: '-150px',
      },
      // Decrease margin on xs screens
      [theme.breakpoints.down('sm')]: {
        marginTop: '80px',
        marginBottom: '-180px',
      },
    },

    gridContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1300px',
      padding: '50px',
      marginBottom: '40px',
      marginTop: '20px',
    },
    aboutUsContainer: {
      width: '100%',
      display: 'flex',
      minHeight: '400px',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5px 0px 50px 0px',
      fontFamily: FONT_SECONDARY,
    },
    aboutUsSubtitle: {
      opacity: '0.7',
      fontSize: '16px',
      marginBottom: '20px',
      fontFamily: FONT_SECONDARY,
    },
    aboutUsSubtitle1: {
      opacity: '0.7',
      fontSize: '16px',
      fontFamily: FONT_SECONDARY,
    },
    titleMain: {
      letterSpacing:'-3.12px',
      fontSize: '6.5rem',
      fontWeight: '400',
      lineHeight: '100%',
      marginTop:'70px',
      paddingBottom: '5px',
      color: '#1E2330',
      fontFamily: FONT_SECONDARY,
      [theme.breakpoints.down('md')]: {
        fontSize:'5rem'
      },
    },

    titleMain2: {
      letterSpacing:'-3.12px',
      fontSize: '6.5rem',
      lineHeight: '100%',
      fontWeight: '700',
      paddingBottom: '10px',
      color: '#1E2330',
      [theme.breakpoints.down('md')]: {
        fontSize:'5rem'
      },
    },
    title: {
      paddingBottom: '15px',
      fontFamily: FONT_SECONDARY,
      [theme.breakpoints.down('md')]: {
        fontSize:'2.3rem'
      },
    },
    miniTitle: {
      color: colors.GRAY_500,
      fontWeight: 600,
      fontSize: '0.85rem',
      fontFamily: FONT_SECONDARY,
    },
    subtitleMain: {
      opacity: '0.7',
      paddingBottom: '30px',
      color: '#fff'
    },
    subtitle: {
      opacity: '0.4',
      paddingBottom: '30px'
    },
    largeImage: {
      width: '100%',
    },
    mediumImage: {
      width: '70%',
    },
    sectionGridContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    sectionTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    sectionGridItem: {
      padding: '20px',
      width: '200px',
      backgroundColor:'#fff',
      borderRadius: '12px',
      margin: '10px !important',
      border: 'solid',
      borderWidth: 1, 
      borderColor:'#e4e4e7'
    },
    sectionGridItem1: {
      width: '550px',
      borderRadius: '12px',
      padding: '18px',
    },
    inputField: {
      marginBottom: '20px !important',
    },
    textArea: {
      width: '100%',
      marginBottom: '20px',
      fontSize: '16px',
      padding: '10px',
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      miHeight: '10vh',
      padding: '20px',
      justifyContent: 'center',
      backgroundColor: '#f2f0f1',
      flexDirection: 'column',
    },
    footerText: {
      paddingBottom: '10px',
    },
    footerDate: {
      opacity: '0.4',
    },
    testimonialCard: {
      backgroundColor: '#fff',
      padding: '10px',
      minHeight: '200px',
      display: 'flex',
      alignItems: 'center',
    },
    testimonialStatement: {
      paddingBottom: '25px',
    },
    avatar: {
      marginRight: '10px',
    },
    testimonialPosition: {
      fontSize: '14px',
      opacity: '0.6',
    },
    normalButton: {
      margin:'8px',
      fontFamily: FONT_SECONDARY,
      padding: '6px', 
      minHeight: '50px',
      fontSize: '16px',
      backgroundColor: colors.DARKONE,
      color: '#fff',
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      borderRadius: '12px',
      boxShadow: 'none',
      "&:hover": {
        backgroundColor: colors.BLACK,
        color: '#fff'
      },
    },
    secondButton: {
      margin:'8px',
      padding: '6px', 
      fontFamily: FONT_SECONDARY,
      minHeight: '50px',
      fontSize: '16px',
      backgroundColor: colors.TRANSPARENT,
      color: colors.DARKONE,
      border: `solid 1px ${colors.DARKONE}`,
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      borderRadius: '12px',
      boxShadow: 'none',
      "&:hover": {
        backgroundColor: colors.BLACK,
        color: '#fff'
      },
    },
  };
};

const useStyles = makeStyles(styles);
export default useStyles;