import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthAction = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode');

    if (mode === 'resetPassword') {
      navigate(`/reset-password${location.search}`);
    } else {
      const firebaseActionUrl = 'https://shazam-africa.firebaseapp.com/__/auth/action';
      window.location.href = `${firebaseActionUrl}${location.search}`;
    }
  }, [location, navigate]);

  return <div>Redirecting...</div>;
};

export default AuthAction;
