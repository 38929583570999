import React, { useState, useEffect} from 'react';
import { Grid, Typography, Button, Box, } from '@mui/material';
import backgroundImage from '../assets/img/background.jpg';
import useStyles from '../styles/styles';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import heroImage from '../assets/img/car-big.png';
import {calcEst, showEst, optionsRequired, FONT_FAMILY, FONT_SECONDARY} from '../common/sharedFunctions';

const Hero = (props) => {
  const classes = useStyles();
  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = useSelector(state => state.auth);

  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  return (
    <Box className={classes.heroBox} sx={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }} style={{direction:isRTL === 'rtl'? 'rtl':'ltr'}}>
      <Grid container spacing={12} className={classes.gridContainer} style={{ flexDirection: 'row-reverse' }}>
          <Grid 
            className={classes.heroImg}
            item xs={12} md={5} lg={5} 
            style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              flexDirection: 'column' 
            }} >
            <img 
              src={heroImage} 
              alt="hero_logo" 
              style={{ 
                width: '100%', 
                height: 'auto',
                marginBottom: '16px',
                marginTop: '16px',
                flexDirection: 'column',
              }} 
            />
          </Grid>
          <Grid 
            className={classes.heroTitleSection}
            item xs={12} md={7} lg={7} style={{ 
              display: 'flex', 
              flexDirection: 'column',
            }}>
            <Typography variant="h2" fontWeight={500} className={classes.titleMain}
              style={{ color: '#fff',fontFamily: FONT_SECONDARY,}}>
              {t('book_your_title')}
            </Typography>
            <Typography variant="h2" fontWeight={600} className={classes.titleMain2}
              style={{ color: '#fff',fontFamily: FONT_SECONDARY,}}>
              {t('book_your_title_2')}
            </Typography>
            <Typography 
              variant="h6" 
              className={classes.subtitleMain} 
              style={{ 
                color: 'white', 
                fontFamily: FONT_FAMILY,
              }}
            >
              {t('hero_subtitle_text')}
            </Typography>
            <Button
              variant="contained"
              style={{ 
                width: '200px', 
                color: '#FFF', 
                borderRadius: '6.25rem', 
                backgroundColor: '#1E2330', 
                fontSize: '16px', 
                padding: '18px 18px 20px' 
              }}
              onClick={(e) => { 
                e.preventDefault();
                if(loggedIn){
                  navigate(((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings')
                } else {
                  navigate('/login')
                }
              }}
            >
              {role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info') : t('book_your_ride_menu')}
            </Button>
          </Grid>
        </Grid>
    </Box>
  );
};

export default Hero;