import React, { useState, useEffect} from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import useStyles from '../styles/styles';
import { useTranslation } from "react-i18next";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import backImage from '../assets/img/back.png';
import aboutImage from '../assets/img/about.jpg';
import riderImage from '../assets/img/rider.jpg';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {calcEst, showEst, optionsRequired} from '../common/sharedFunctions';
import {FONT_FAMILY, FONT_SECONDARY} from "../common/sharedFunctions";
import { colors } from './Theme/WebTheme';
import CallMadeIcon from '@mui/icons-material/CallMade';
import TollIcon from '@mui/icons-material/Toll';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Section = (props) => {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  const cartypes = useSelector(state => state.cartypes);
  const [data, setData] = useState([]);
  const auth = useSelector(state => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars.sort((a, b) => a.pos - b.pos));
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  const navigate = useNavigate();
  const role = props.role;
  const color=props.color;
  
  const sectionItems = [
    {
      id: 1,
      icon:  <EmojiTransportationIcon style={{border:'solid',borderWidth:1,color:'#C8D8F4',fontSize:'30px',marginRight:5,padding:'6px',borderRadius:'8px'}} />,
      sentence1:t('pruduct_section_heading_1'),
      sentence: t('product_section_1'),
    },
    {
      id: 2,
      icon:  <AccessTimeIcon style={{border:'solid',borderWidth:1,color:'#C8D8F4',fontSize:'30px',marginRight:5,padding:'6px',borderRadius:'8px'}} />,
      sentence1:t('pruduct_section_heading_2'),
      sentence: t('product_section_2'),
    },
  ];
  const sectionItems1 = [
    {
      id: 1,
      icon:   <CarCrashOutlinedIcon style={{border:'solid',borderWidth:1,color:'#C8D8F4',fontSize:'30px',marginRight:5,padding:'6px',borderRadius:'8px'}} />,
      sentence1:t('pruduct_section_heading_3'),
      sentence: t('product_section_3'),
    },
    {
      id: 2,
      icon:  <VerifiedIcon style={{border:'solid',borderWidth:1,color:'#C8D8F4',fontSize:'30px',marginRight:5,padding:'6px',borderRadius:'8px'}} />,
      sentence1:t('pruduct_section_heading_4'),
      sentence: t('product_section_4'),
    },
  ];

  const Boxs = () => {
    return(
      <Box>
      <Box sx={{ flexGrow: 1,minHeight:data.length > 0 ? '480px' : '160px',paddingTop:15,marginBottom:1,paddingVertical:5, backgroundColor:colors.LIGHTONE,position:'relative'}}>
          <Box sx={{
                    position:'relative',
                    maxWidth:'80vw',
                    marginLeft: 'auto',
                    marginRight: 'auto',}}>
             <div container className={classes.sectionTitle}>
              <div>
                <Typography variant="h3" className={classes.title} fontWeight={600}>
                  {t('booking_section_title')}
                </Typography>
                <Typography className={classes.aboutUsSubtitle}>
                  {t('booking_section_body')}
                </Typography>
               </div>
              </div>
          </Box>
        {data && data.length > 0 ?
          <Grid container className={classes.sectionGridContainer}>
            {data.map((item, key) => {
              return (
                <div style={{marginBottom: 60 }} key={key}>
                  <Box
                    xs={12}
                    md={4}
                    height={350}
                    minHeight={350}
                    width={300}
                    minWidth={275}
                    className={classes.sectionGridItem}
                    style={{ backgroundColor:colors.WHITE,textAlign:'center',}}
                  >
                    <img alt='Car' src={item.image} style={{ width: 150, height: 150 }} />
                    <Typography style={{ margin:5,color:'black',fontSize:18,fontWeight:'bold',fontFamily:FONT_SECONDARY }}>{item.name}</Typography>
                    <Box style={{height:215,borderRadius: 16,padding: 2}}>
                      <Grid style={{padding:10,backgroundColor:colors.LIGHTONE,display:'flex',width:'100%',borderRadius:'8px',marginBottom:10,justifyContent:'space-around',}}>
                        <Box lg={8} style={{textAlign:'left',justifyContent:'left',color:'#71717a',alignContent:'center'}}>
                            <Typography style={{ marginTop:5 ,fontFamily:FONT_FAMILY,fontSize:12,}}>{t('min_fare')}:{item.min_fare}</Typography>
                            <Typography style={{ marginTop:2,fontFamily:FONT_FAMILY,fontSize:12,}}>{t('extra_info')}:{item.extra_info}</Typography>
                        </Box>
                        <Box lg={4} style={{alignContent:'center'}}>
                          {settings.swipe_symbol === false ?
                          <Typography style={{ marginTop: 2,fontWeight:'bold',fontSize:24, fontFamily:FONT_FAMILY }}>{settings.symbol}{item.rate_per_unit_distance}/{settings.convert_to_mile ? t('mile') : t('km')}</Typography>
                          :
                          <Typography style={{ marginTop: 2,fontWeight:'400',fontSize:24, fontFamily:FONT_FAMILY }}>{item.rate_per_unit_distance}{settings.symbol}/{settings.convert_to_mile ? t('mile') : t('km')}</Typography>
                          }
                        </Box>
                      </Grid>
                      <Button
                        variant="contained"
                        style={{color:'#FFF',width:'100%',borderRadius:'16px',backgroundColor:'#1E2330', fontSize:'14 px', padding:'10.5px 15px 12px',boxShadow:"none",textTransformation:"none",fontWeight:400}}
                        onClick={(e) => { e.preventDefault(); 
                          if(loggedIn){
                          navigate(((role && role === 'driver' )|| (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings', 
                          { state: { carData: item }} )
                          }else{
                            navigate('/login')
                          }
                        }}
                      > 
                        { role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info'): t('book_now')}{` `}{item.name}
                      </Button>
                    </Box>
                  </Box>
                </div>
              )
            })}
          </Grid>
        :
          <Typography variant="h3" fontWeight={400} style={{ textAlign: 'center', padding: 35, color: color.Header}}>{t('service_start_soon')}</Typography>
        }
      </Box>
      <Box className={classes.aboutUsContainer} style={{marginBottom: -15,display:'flex',flexGrow:1,minHeight:'400px',backgroundColor:colors.WHITE,margin:'0 auto',position:'relative'}}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <img src={riderImage} alt="about-riders" className={classes.mediumImage} />
        </Grid>
        <Grid item xs={12} md={5}>
            <Typography variant="h6" className={classes.miniTitle}>
              {t('rider_section_min_title')}
            </Typography>
            <Typography variant="h3" className={classes.title} fontWeight={600}>
              {t('rider_section_title')}
            </Typography>
            <Typography className={classes.aboutUsSubtitle}>
              {t('rider_section_body')}
            </Typography>
            <div style={{padding:'20px'}}>
              <div style={{display:'flex',paddingTop:'10px'}}>
                <TollIcon />
                <Box style={{paddingLeft:'20px',display:'block'}}>
                  <Typography style={{fontSize:18,fontWeight: 600,color: colors.GRAY_600,marginBottom:'5px',fontFamily: FONT_SECONDARY,}}>{t('ri_section_t1')}</Typography>
                  <Typography style={{fontSize:16,fontWeight:400,opacity:'0.7',marginBottom: '10px',fontFamily: FONT_SECONDARY,}}>{t('ri_section_b1')}</Typography>
                </Box>
              </div>
              <div style={{display:'flex',paddingTop:'10px'}}>
                <ConnectWithoutContactIcon />
                <Box style={{paddingLeft:'20px',display:'block'}}>
                  <Typography style={{fontSize:18,fontWeight: 600,color: colors.GRAY_600,marginBottom:'5px',fontFamily: FONT_SECONDARY,}}>{t('ri_section_t2')}</Typography>
                  <Typography style={{fontSize:16,fontWeight:400,opacity:'0.7',marginBottom: '10px',fontFamily: FONT_SECONDARY,}}>{t('ri_section_b2')}</Typography>
                </Box>
              </div>
              <div style={{display:'flex',paddingTop:'10px'}}>
                <DirectionsBikeIcon />
                <Box style={{paddingLeft:'20px',display:'block'}}>
                  <Typography style={{fontSize:18,fontWeight: 600,color: colors.GRAY_600,marginBottom:'5px',fontFamily: FONT_SECONDARY,}}>{t('ri_section_t3')}</Typography>
                  <Typography style={{fontSize:16,fontWeight:400,opacity:'0.7',marginBottom: '10px',fontFamily: FONT_SECONDARY,}}>{t('ri_section_b3')}</Typography>
                </Box>
              </div>                
            </div>
            <Button variant="contained" className={classes.secondButton}>
                {t('join_riders')}
                <KeyboardArrowRightIcon style={{color:'colors.DARKONE'}}/>
            </Button> 
        </Grid>
      </Grid>
      </Box>
      </Box>
    )
  }
  return (
    <div>
          <Boxs/>
      <Box sx={{ flexGrow: 1,minHeight:'350px',backgroundImage:`url(${backImage})`,backgroundSize:'cover'}}>
        <Typography variant="h3" fontWeight={600} style={{margin:"auto",width:"80vw",color:colors.CAR_BOX_ODD, fontFamily:FONT_SECONDARY, textAlign:'center',marginTop:35,paddingTop:40,paddingBottom:10,paddingHorizontal:30}}>{t('best_service_provider')}</Typography>
        <Typography variant="h5" className={classes.subtitleMain}  style={{margin:"auto",width:"80vw",fontSize:16,color:'white',fontFamily: FONT_FAMILY,textAlign:'center',paddingBottom:40}}>{t('feature_service_text')}</Typography>
        <Grid container className={classes.sectionGridContainer}>
          {sectionItems.map((item, key) => {
            return (
              <Box
                key={key} 
                xs={12}
                md={3.5}
                minHeight={120}
                className={classes.sectionGridItem1}
              >
               <div style={{
                  display:'flex',
                  backgroundColor:'rgba(255, 255, 255, .15)',
                  backdropFilter:'blur(5px)',
                  padding:'20px',
                  borderRadius:'12px',
                  justifyContent:'center',
                  alignItem:'center'}}>
                  {item.icon}
                  <div>
                    <Typography variant="h5" style={{fontSize:16,color:colors.CAR_BOX_ODD, fontFamily:FONT_FAMILY, fontWeight: 'bold'}}>{item.sentence1}</Typography>
                    <Typography style={{fontSize:16,color:colors.WHITE, fontFamily:FONT_FAMILY,}} className={classes.aboutUsSubtitle1}>{item.sentence}</Typography>
                  </div>
                </div> 
              </Box>
            )
          })}
        </Grid>
        <Grid container className={classes.sectionGridContainer}>
          {sectionItems1.map((item, key) => {
            return (
              <Box
                key={key}
                xs={12}
                md={3.5}
                minHeight={120}
                
                className={classes.sectionGridItem1}
              >
                <div style={{
                  display:'flex',
                  backgroundColor:'rgba(255, 255, 255, .15)',
                  backdropFilter:'blur(5px)',
                  padding:'20px',
                  borderRadius:'12px',
                  justifyContent:'center',
                  alignItem:'center'}}>
                  {item.icon}
                  <div>
                    <Typography variant="h5" style={{fontSize:16,color:colors.CAR_BOX_ODD, fontFamily:FONT_FAMILY, fontWeight: 'bold' }}>{item.sentence1}</Typography>
                    <Typography style={{fontSize:16,color:colors.WHITE, fontFamily:FONT_FAMILY,}} className={classes.aboutUsSubtitle1}>{item.sentence}</Typography>
                  </div>
                </div> 
              </Box>
            )
          })}
        </Grid>
      </Box>
      <Box className={classes.aboutUsContainer} style={{marginBottom: -15,display:'flex',flexGrow:1,minHeight:'400px',backgroundColor:colors.LIGHTONE,margin:'0 auto',position:'relative'}}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.miniTitle}>
              {t('about_section_min_title')}
            </Typography>
            <Typography variant="h3" className={classes.title} fontWeight={600}>
              {t('about_section_title')}
            </Typography>
            <Typography className={classes.aboutUsSubtitle}>
              {t('about_section_body')}
            </Typography>
            <div>
              <Button variant="contained" className={classes.normalButton}>
                {t('learn_more_btn')}
                </Button>
              <Button variant="contained" className={classes.secondButton}>
                {t('join_community')}
                <CallMadeIcon style={{color:'colors.DARKONE'}}/>
              </Button>
            </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={aboutImage} alt="about shazam" className={classes.largeImage} />
        </Grid>
      </Grid>
      </Box>
    </div>
  );
};

export default Section;