import React,{ useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  FormControl,
  FormLabel,
  Select,
  MenuItem
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Chart from 'react-apexcharts';
import moment from 'moment/min/moment-with-locales';
import WalletCard from '../components/WalletCard';
import AlertDialog from '../components/AlertDialog';
import AlertDialogTwoButton from '../components/AlertDialogTwoButton';
import styles from '../styles/landingPage.js';
import { useNavigate } from 'react-router-dom';
import {colors} from '../components/Theme/WebTheme';
import { FONT_FAMILY, BLACK_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { ThemeProvider } from '@mui/material/styles';
import theme from "styles/tableStyle";

const useStyles = makeStyles(theme => ({
  ...styles,
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: '12px',
  },
  inputRtl: {
    "& label": {
      right: 10,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20
    }
  },
  textField: {
    "& label.Mui-focused": {
      color: BLACK_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: BLACK_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: BLACK_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: BLACK_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: BLACK_COLOR,
    },
  },
}));

const icons = {
  'paypal':require('../assets/payment-icons/paypal-logo.png').default,
  'braintree':require('../assets/payment-icons/braintree-logo.png').default,
  'stripe':require('../assets/payment-icons/stripe-logo.png').default,
  'paytm':require('../assets/payment-icons/paytm-logo.png').default,
  'payulatam':require('../assets/payment-icons/payulatam-logo.png').default,
  'flutterwave':require('../assets/payment-icons/flutterwave-logo.png').default,
  'paystack':require('../assets/payment-icons/paystack-logo.png').default,
  'securepay':require('../assets/payment-icons/securepay-logo.png').default,
  'payfast':require('../assets/payment-icons/payfast-logo.png').default,
  'liqpay':require('../assets/payment-icons/liqpay-logo.png').default,
  'culqi':require('../assets/payment-icons/culqi-logo.png').default,
  'mercadopago':require('../assets/payment-icons/mercadopago-logo.png').default,
  'squareup':require('../assets/payment-icons/squareup-logo.png').default,
  'wipay':require('../assets/payment-icons/wipay-logo.png').default,
  'test':require('../assets/payment-icons/test-logo.png').default,
  'razorpay':require('../assets/payment-icons/razorpay-logo.png').default,
  'paymongo':require('../assets/payment-icons/paymongo-logo.png').default,
  'iyzico':require('../assets/payment-icons/iyzico-logo.png').default,
  'slickpay':require('../assets/payment-icons/slickpay-logo.png').default,
}


const UserWallet = (props) => {
  const {
    withdrawBalance,
  } = api;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const providers = useSelector(state => state.paymentmethods.providers);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [profile,setProfile] = useState();
  const [data,setData] = useState([]);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [commonAlertTwoButton, setCommonAlertTwoButton] = useState({ open: false, msg: '' });
  const rootRef = useRef(null);
  const [amount,setAmount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [allInTransaction, setAllInTransaction] = useState([]);
  const [allOutTransaction, setAllOutTransaction] = useState([]);
  const [modalInfo,setModalInfo] = useState({
    mOpen: false,
    mType: null
  });
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);
  const columns =  [
      { title: t('requestDate'), field: 'date', defaultSort:'desc', render: rowData => rowData.date ? moment(rowData.date).format('lll') : null,},
      { title: t('amount'), field: 'amount',editable: 'never',
        render: (rowData) =>
        rowData.amount
        ? settings.swipe_symbol
        ? rowData.amount + " " + settings.symbol
        : settings.symbol + " " + rowData.amount
        : settings.swipe_symbol
        ? "0 " + settings.symbol
        : settings.symbol + " 0",
     },
     { title: t('transaction_id'), field: 'transaction_id', render: rowData => rowData.transaction_id ? rowData.transaction_id : rowData.txRef, },
     { title: t('type'), field: 'type',   render: (rowData) => (
      <div
        style={{
          backgroundColor:
            rowData.type === "debited"
              ? colors.RED_50
              : rowData.type === "credited"
              ? colors.GREEN_50
              : colors.YELLOW_50,
          color: 
            rowData.type === "debited"
            ? colors.RED_500
            : rowData.type === "credited"
            ? colors.GREEN_500
            : colors.YELLOW_300,
          borderColor: 
            rowData.type === "debited"
            ? colors.RED_200
            : rowData.type === "credited"
            ? colors.GREEN_200
            : colors.YELLOW_200,
          padding: 7,
          fontSize:"12px",
          border:`solid 1px`,
          borderRadius: "18px",
          fontWeight: 400,
          width: "100px",
          margin: "auto",
        }}
      >
        {t(rowData.type)}
      </div>
    ),}
  ];

  useEffect(()=>{
    if(providers){
      setSelectedProvider(providers[0]);
    }
  },[providers]);

  useEffect(()=>{
    if(auth.profile){
        setProfile(auth.profile);
        let wdata = auth.walletHistory;
        var wallHis = [];
        for(let key in wdata){
            wdata[key].walletKey = key
            if(wdata[key].type.includes("Credit") || wdata[key].type.includes("credit")){
              wdata[key].type = 'credited'
            }else if(wdata[key].type.includes("Withdraw") || wdata[key].type.includes("withdraw")){
              wdata[key].type = 'withdraw'
            }else{
              wdata[key].type = 'debited'
            }
            wallHis.push(wdata[key])
        }
        if(wallHis.length>0){
          setData(wallHis.reverse());
        }else{
          setData([]);
        }

    } else{
        setProfile(null);
    }
  },[auth.profile,auth.walletHistory]);

  const doRecharge = (e) => {
    e.preventDefault();
    if(!(profile.mobile && profile.mobile.length > 6 && profile.email && profile.firstName)){
      setCommonAlertTwoButton({ open: true, msg: t('profile_incomplete')})
     } else{
      if (providers) {
        setModalInfo({
          mOpen: true,
          mType: 'add'
        });
      } else {
        setCommonAlert({ open: true, msg: t('provider_not_found')})
      }
    }
  }

  const doWithdraw = (e) => {
    e.preventDefault();
    if(!(profile.mobile && profile.mobile.length > 6 && profile.email && profile.firstName)){
      setCommonAlertTwoButton({ open: true, msg: t('profile_incomplete')})
    }else{
        if (parseFloat(profile.walletBalance)>0) {
          setModalInfo({
            mOpen: true,
            mType: 'withdraw'
          });
        } else {
          setCommonAlert({ open: true, msg: t('wallet_bal_low')})
        }
    }
  }

  const gotoOperation = () => {
    navigate('/profile');
    setCommonAlert({ open: false, msg: '' })
  };

  const cancelOperation = () => {
    setAmount(0);
    setModalInfo({
      mOpen: false,
      mType: null
    });
    setCommonAlert({ open: false, msg: '' })
  };

  const cancelOperationTowButton = () => {
    setAmount(0);
    setModalInfo({
      mOpen: false,
      mType: null
    });
    setCommonAlertTwoButton({ open: false, msg: '' })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(amount <= 0){
      setCommonAlert({ open: true, msg: t('valid_amount')});
    } else {
       if(modalInfo.mType === 'add'){
        setModalInfo({
          ...modalInfo,
          mOpen: false
        });
        setPaymentModalStatus(true);
       }else{
        if( parseFloat(profile.walletBalance) < amount){
          setCommonAlert({ open: true, msg: t('valid_amount')});
        }else{
          dispatch(withdrawBalance(profile,amount));
          cancelOperation();
         }
        }
    }
  }

  const createOrderId = () =>{
    const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const reference = [...Array(4)].map(_ => c[~~(Math.random()*c.length)]).join('');
    return "wallet-" + auth.profile.uid + "-" + reference
  }

  const handlePaymentModalClose = (e) => {
    setTimeout(()=>{
      setPaymentModalStatus(false);
    },1500);
  }
  const inputStyle = { fontFamily: FONT_FAMILY, };

  const barChartOptions = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    colors: [colors.GREEN_400, colors.MUTE_ORANGE],
    options: {
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        }, 
    },
    chart: {
            height: 180,
            type: 'area',
            fontFamily: FONT_FAMILY 
        },
    xaxis: {
        labels: {
            style: {
                fontFamily: FONT_FAMILY 
            }
        }
    },
    yaxis: {
        labels: {
            style: {
                fontFamily: FONT_FAMILY 
            }
        }
    },
    title: {
        style: {
            fontFamily: FONT_FAMILY
        }
    },
    subtitle: {
        style: {
            fontFamily: FONT_FAMILY 
        }
    },
    tooltip: {
        style: {
            fontFamily: FONT_FAMILY 
        }
    },
    legend: {
        fontFamily: FONT_FAMILY 
    }
  };

  const barChart = [{
        name: t('in_transaction'),
        data: allInTransaction
    }, {
        name: t('out_transaction'),
        data: allOutTransaction
  }];

  useEffect(() => {
    let allInTransaction = [];
    let allOutTransaction = [];
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if (bookinglistdata.bookings) {
        for (let i = 0; i < months.length; i++) {
            let InTransaction = 0;
            let OutTransaction = 0;
            for (let j = 0; j < bookinglistdata.bookings.length; j++) {
                const { tripdate } = bookinglistdata.bookings[j];
                let tDate = new Date(tripdate);
                if (months[i] === months[tDate.getMonth()] && (bookinglistdata.bookings[j].status === 'PAID' || bookinglistdata.bookings[j].status === 'COMPLETE')) {
                    InTransaction = InTransaction + 1;
                }
                if (months[i] === months[tDate.getMonth()] && bookinglistdata.bookings[j].status === 'CANCELLED') {
                    OutTransaction = OutTransaction + 1;
                }
            }
            allInTransaction.push(InTransaction);
            allOutTransaction.push(OutTransaction);
        }
    }
    setAllInTransaction(allInTransaction);
    setAllOutTransaction(allOutTransaction);
}, [bookinglistdata.bookings]);
 
  return (
    <div>
      <div  style={{borderRadius: "20px", padding: 10}}>
        <Typography variant="h4" 
             style={{...inputStyle,margin:"20px 20px 20px 15px",
                        textAlign:isRTL==='rtl'?'right':'left',
                        fontWeight: 500,
                        fontSize: 24,
                      }}>
                          {t('my_wallet_title')}
        </Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item xs style={{textAlign:'center' }}>
            {settings.swipe_symbol===false?
               <WalletCard crdStyle={{boxShadow:"none",border: `1px solid ${colors.FOOTERTOP}`,backgroundColor:colors.WHITE,borderRadius: "8px",height:'300px'}} > 
                <Typography variant="h6" style={{color:'black',paddingBottom:15,fontSize:15,fontWeight:600,margin:"10px 20px 0 15px",fontFamily:FONT_FAMILY}}>{t('Balance')}</Typography>
                <Typography variant="h6" style={{margin:20,color:'black',fontWeight:600,fontSize:40,fontFamily:FONT_FAMILY}}>
                  { settings.symbol + ' ' + (profile && profile.hasOwnProperty('walletBalance') ?profile.walletBalance : '')}
                  </Typography>
                <Button onClick={doRecharge} variant="contained" style={{width:'100%', backgroundColor:colors.DARKONE,color:"white", borderRadius: "8px"}}  size='large'>{t('add_to_wallet')}
                </Button>
                </WalletCard>
              :
              <WalletCard crdStyle={{boxShadow:"none",border: `1px solid ${colors.FOOTERTOP}`,backgroundColor:colors.WHITE,borderRadius: "8px",height:'300px'}} >
                <Typography variant="h6" style={{margin:20,color:'black',paddingBottom:15,fontSize:15,fontWeight:600,fontFamily:FONT_FAMILY}}>
                  {t('Balance')}
                  </Typography>
                <Typography variant="h6" style={{color:'black',fontWeight:600,fontSize:40,fontFamily:FONT_FAMILY}}>
                  { (profile && profile.hasOwnProperty('walletBalance')?profile.walletBalance : '') + ' ' + settings.symbol }
                  </Typography>
                  <Button onClick={doRecharge} variant="contained" style={{width:'100%', backgroundColor:colors.DARKONE,color:"white", borderRadius: "8px"}}  size='large'>{t('add_to_wallet')}
                  </Button>
               </WalletCard>
            }
          </Grid>
          <Grid item xs style={{textAlign:isRTL==='rtl'?'right':'left', display:'flex',  direction:"row"}}>
            <Box style={{width:'100%',borderRadius:12,backgroundColor:colors.WHITE,border:`1px solid ${colors.FOOTERTOP}`,}}>
               <Typography style={{paddingBottom:15,fontSize:15,fontWeight:600,margin:"10px 20px 0 15px",fontFamily:FONT_FAMILY}} variant="h4">{t('spending_chart')}</Typography>
                    <Chart
                        options={barChartOptions}
                        series={barChart}
                        type="area"
                        width="100%"
                        height={200}
                    />
                </Box>
          </Grid>

          {(settings && settings.RiderWithDraw && auth.profile.usertype === 'customer') || auth.profile.usertype !== 'customer' ?
            <Grid item xs style={{textAlign:isRTL==='rtl'?'right':'left', display:'flex',  direction:"row"}}>
              <Button onClick={doWithdraw} variant="contained" style={{width:'100%', backgroundColor:'#2CDE3A', color:"white", borderRadius: "8px"}} size='large'>{t('withdraw')}</Button>
            </Grid>
          :null}
        </Grid>
      </div>
      <ThemeProvider theme={theme}>
      <MaterialTable
         title={t('transaction_history_title')}
         columns={columns}
         style={{
          direction:isRTL ==='rtl'?'rtl':'ltr', 
          padding: 10, 
          marginTop: 30, 
          borderRadius: "8px",
          boxShadow:"none",
          border: `1px solid ${colors.FOOTERTOP}`,
        }}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          exportButton: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE
        }),
          editable:{
            backgroundColor: colors.LandingPage_Background,
            fontSize: "0.8em",
            fontWeight: 'bold ',
          },
          headerStyle: {
            backgroundColor: SECONDORY_COLOR ,
            color: colors.CARD_LABEL,
            textAlign: "center",
            fontSize: "0.8em",
            lineHeight: "1.2em",
            fontWeight: 'bold ',
            borderBottom: `1px solid ${colors.TABLE_BORDER}`,
          },
          cellStyle: {
            borderBottom: `1px solid ${colors.TABLE_BORDER}`,
            textAlign: "center",
            margin: "auto",
          },
        }}
        localization={{
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
        }}
      />
      </ThemeProvider>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={paymentModalStatus}
        onClose={handlePaymentModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.paper}>
        {providers && selectedProvider && modalInfo.mType === 'add'  && amount>0?
          <form action={selectedProvider.link} method="POST">
            <input type='hidden' name='order_id'style={inputStyle} value={createOrderId()}/>
            <input type='hidden' name='amount' style={inputStyle} value={amount}/>
            <input type='hidden' name='currency' style={inputStyle} value={settings.code}/>
            <input type='hidden' name='product_name' style={inputStyle} value={t('add_money')}/>
            <input type='hidden' name='first_name' style={inputStyle} value={profile.firstName}/>
            <input type='hidden' name='last_name' style={inputStyle} value={profile.lastName}/>
            <input type='hidden' name='quantity' style={inputStyle} value={1}/>
            <input type='hidden' name='cust_id' style={inputStyle} value={auth.profile.uid}/>
            <input type='hidden' name='mobile_no' style={inputStyle} value={profile.mobile}/>
            <input type='hidden' name='email' style={inputStyle} value={profile.email}/>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{marginBottom: '20px'}}>
              <FormControl fullWidth style={inputStyle}>
              <FormLabel component="legend" style={inputStyle} >{t('payment')}</FormLabel>
              <Select
               fullWidth
                  id="selectedProviderIndex"
                  name= "selectedProviderIndex"
                  value={selectedProviderIndex}
                  label={t('payment')}
                  onChange={(e)=>{
                      setSelectedProviderIndex(parseInt(e.target.value));
                      setSelectedProvider(providers[parseInt(e.target.value)]);
                  }}
                  style={{textAlign:isRTL==='rtl'? 'right':'left', ...inputStyle}}
                  inputProps={{ 'aria-label': 'Without label' , ...inputStyle}}
                >
                  {providers.map((provider,index) =>
                     <MenuItem key={provider.name} value={index} style={{ width:'100%', justifyContent:'center', paddingLeft:10}}>
                      <img style={{height:24,margin:7}} src={icons[provider.name]} alt={provider.name}/>
                      </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button onClick={handlePaymentModalClose} variant="contained" color="primary" 
            style={{
                boxShadow:'none',
                border: `1px solid ${colors.FOOTERTOP}`,
                color: colors.RED_500,
                padding:10,
                backgroundColor:colors.RED_50,
                borderRadius:8, ...inputStyle}}>
              {t('cancel')}
            </Button>
            <Button variant="contained" color="primary" type="submit" onClick={handlePaymentModalClose}
            style={{
               boxShadow:'none',
               border: `1px solid ${colors.GREEN_400}`,
               color: colors.GRAY_600,
               marginLeft:10,
               padding:10,
               backgroundColor:colors.GREEN_50,
               borderRadius:8 , ...inputStyle}}>
              {t('paynow_button')}
            </Button>
            </Grid>
          </form>
          :null}
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={modalInfo.mOpen}
        onClose={cancelOperation}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
            <Typography component="h2" variant="h5" style={{marginTop:15, color:colors.BLACK , ...inputStyle}}>
                {t('amount')}
            </Typography>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="amount"
                label={t('amount')}
                name="amount"
                autoComplete="amount"
                sx={{
                  '& .MuiInputBase-root': {
                    fontFamily: FONT_FAMILY,
                  },
                  '& .MuiInputLabel-root': {
                    fontFamily: FONT_FAMILY,
                  },
                }}
                onChange={(e)=>{
                  try{
                    if(e.target.value === ""){
                      setAmount(0);
                    }else{
                      setAmount(parseFloat(e.target.value));
                    }
                  }catch(e){
                    setCommonAlert({ open: true, msg: t('no_details_error')});
                    setAmount(0);
                  }          
                }}
                value={amount.toString()}
                autoFocus
                className={isRTL==='rtl'?classes.inputRtl:null}
                style={{direction:isRTL==='rtl'?'rtl':'ltr' }}
              />
            </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button onClick={cancelOperation} variant="contained" color="primary" 
              style={{
                boxShadow:'none',
                border: `1px solid ${colors.FOOTERTOP}`,
                color: colors.RED_500,
                padding:10,
                backgroundColor:colors.RED_50,
                borderRadius:8, ...inputStyle}}>
              {t('cancel')}
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary" 
              style={{
                boxShadow:'none',
                border: `1px solid ${colors.GREEN_400}`,
                color: colors.GRAY_600,
                marginLeft:10,
                padding:10,
                backgroundColor:colors.GREEN_50,
                borderRadius:8 , ...inputStyle}}>
              {modalInfo.mType === 'add'? t('add_to_wallet'): t('withdraw')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog open={commonAlert.open} onClose={cancelOperation}>{commonAlert.msg}</AlertDialog>
      <AlertDialogTwoButton open={commonAlertTwoButton.open} onClose={cancelOperationTowButton} onGoto={gotoOperation}>{commonAlertTwoButton.msg}</AlertDialogTwoButton>
    </div>
  );
}

export default UserWallet;
