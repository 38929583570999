import { container } from "./material-kit-react.js";
import {FONT_SECONDARY, BLACK_COLOR} from "../common/sharedFunctions.js";
import { colors } from 'components/Theme/WebTheme.js';

const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    color: "#FFFFFF",
    paddingBottom: "20px"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "-8px",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%"
    }
  },
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginTop: "-20px",
    marginBottom: "15px"
  },

  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },

  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "8px",
    justifyContent: "center !important"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0",
    width: "100%"
  },
  inputIconsColor: {
    color: "#BFCACD",
    fontSize: "18px"
  },
  forgotButton:{
    margin:0,
    marginTop: "-20px",
    textTransform: "none",
    padding: '0 0',
    color: '#828282',
    fontSize:10
  },
  normalButton:{
    margin:'2px',
    width:"100%",
    fontWeight: 600,
    backgroundColor: "#1E2330",
    color: '#fff',
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: '#000',
      color: '#fff'
    },
  },
  textButton:{
    margin:'2px',
    color: '#1E2330',
    "&:hover": {
      backgroundColor: '#000',
      color: '#fff'
    },
  },
  plainButton: {
    fontSize: 16,
    color: "#000",
    fontWeight:600,
    marginTop: "-15px",
    textDecoration: "underline",
    "&:hover": {
      color: '#780016!important',
    },
  },
  cancelButton: {
    fontSize: 16,
    color: "#dc2626",
    fontWeight:600,
    backgroundColor: "#fef2f2",
    padding: "0px 5px",
    marginTop: "5px",
    "&:hover": {
      color: '#780016!important',
    },
  },
  btnHolder: {
    display: "grid",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },

  inputRtl: {
    "& label": {
      transformOrigin: "top left",
      right: 28,
      left: "auto"
    },
    "& legend": {
      textAlign: "right"
    }
  },
    pageWrapper: {
      display: 'flex',
      minHeight: '100vh',
      '@media (max-width: 960px)': {
        flexDirection: 'column',
      },
    },

    leftPanel: {
      flex: 1,
      padding: '32px',
      paddingLeft: "50px",
      backgroundColor: colors.LIGHTONE, // Replace with your primary color
      backgroundImage: 'url(YourBackgroundImagePath)', // Replace with your image path
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',

      '@media (max-width: 960px)': {
        display: 'none!important',
      },
    },
    homeHeader: {
      width:'100%',
      padding: '10px',
      position:'relative',
    },

    rightPanel: {
      flex: 1,
      backgroundColor:"#F3F3F1",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '32px',
      flexDirection: 'column',
      '@media (max-width: 600px)': {
       padding: '16px',
    },
    },
    formCard: {
      backgroundColor: "#fff",
      border: `1px solid #e4e4e7`,
      borderRadius: '8px',
      padding: '30px',
      width: '100%',
      maxWidth: 600,
      boxSizing: 'border-box',
    '@media (max-width: 600px)': {
      padding: '20px', 
    },
    },
    formTitle: {
      fontSize: "38px",
      lineHeight: "44px",
      letterSpacing: "-1px",
      color: BLACK_COLOR,
      fontFamily: FONT_SECONDARY, 
      fontWeight:600,
      textAlign: "left",
      marginTop: "-20px",
      marginBottom: "2px",
    },
    formTitle2: {
      fontSize: "24px",
      lineHeight: "44px",
      letterSpacing: "-1px",
      color: BLACK_COLOR,
      fontFamily: FONT_SECONDARY, 
      fontWeight:600,
      textAlign: "left",
      marginTop: "20px",
      marginBottom: "2px",
      alignSelf:'center',
    },
    formSubtitle: {
      fontSize: "16px",
      lineHeight: "1.5rem",
      letterSpacing: "0px",
      color: colors.CARD_LABEL,
      fontFamily: FONT_SECONDARY,
      fontWeight:400,
      textAlign: "left",
    },
    logininfoTitle: {
      fontSize: "3.5rem",
      lineHeight: "4rem",
      fontFamily: FONT_SECONDARY,
      color: colors.WHITE,
      fontWeight: 700,
      width: "40vw",
    },
    logininfoSubtitle: {
      fontSize: "18px",
      lineHeight: "2rem",
      fontFamily: FONT_SECONDARY,
      color: colors.WHITE,
      width: "30vw",
      marginTop: "-10px",
    },

    invertButton: {
      fontSize: "16px",
      fontWeight: 600,
      backgroundColor: "#fff",
      color: '#000',
      boxShadow: "none",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      borderRadius: '8px',
      "&:hover": {
        backgroundColor: colors.LIGHTONE,
        color: '#000'
      },
    },




    stepContainer: {
      marginBottom: "30px",
    },
    stepTitle: {
      fontSize: "14px",
      fontWeight: "normal",
      marginBottom: "20px",
      color: colors.CARD_LABEL,
      fontFamily: FONT_SECONDARY,
    },
    radioGroup: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
    },
    radioOption: {
      display: "flex",
      backgroundColor: colors.WHITE,
      alignItems: "center",
      padding: "10px",
      border: `1px solid ${colors.GRAY_200}`,
      borderRadius: "8px",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: "#dbeafe",
      },
    },
    radioOptionSelected: {
      backgroundColor: "#dbeafe",
      borderColor: colors.SECONDARYONE,
    },
    radioInput: {
      marginRight: "12px",
    },
    radioLabel: {
      fontSize: "14px",
      fontWeight: "500",
      color: BLACK_COLOR,
      fontFamily: FONT_SECONDARY,
    },
    stepIndicator: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    stepDot: {
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      border: `1px solid ${colors.FOOTERTOP}`,
      backgroundColor: "#F3F3F1",
      margin: "0 5px",
    },
    stepDotActive: {
      border: `1px solid ${colors.FOOTERTOP}`,
      backgroundColor: colors.SECONDARYONE,
    },
};

export default signupPageStyle;
