import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import PasswordIcon from '@mui/icons-material/Password';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import HomeIcon from '@mui/icons-material/Home';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CircularProgress from '@mui/material/CircularProgress';
import styles from '../styles/loginPage.js';
import { getAuth, confirmPasswordReset } from "firebase/auth";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(styles);

const ResetPwd = () => {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth(); // Initialize Firebase Auth
  const { t } = useTranslation();
  const handlePasswordReset = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);
    const oobCode = new URLSearchParams(location.search).get('oobCode');

    try {
      await confirmPasswordReset(auth, oobCode, password);
      navigate('/login');
    } catch (err) {
      setError('Failed to reset password. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.rightPanel}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12} lg={8}>
            <Card className={classes.formCard}>
            <div className={classes.homeHeader}>
                <Button 
                    onClick={(e) => { e.preventDefault(); navigate('/') }}
                    style={{backgroundColor: '#FFF', width:'50px', borderRadius:'8px', color:'#333', padding:'5px'}}>
                    <ArrowLeftIcon />
                    <HomeIcon style={{marginLeft:'-10px', fontSize:'40px'}} />
            </Button>
                </div>
                <h4 className={classes.formTitle2}>{t('reset_page_title')}</h4>
              <CardBody>
                <CustomInput
                  labelText="New Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password",
                    endAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <CustomInput
                  labelText="Confirm Password"
                  id="confirmPassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password",
                    endAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
              </CardBody>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px' }}>
                <Button 
                  className={classes.normalButton} 
                  simple 
                  size="lg" 
                  onClick={handlePasswordReset}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Reset Password'}
                </Button>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default ResetPwd;
